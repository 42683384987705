@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: Jost-Regular, Helvetica, system-ui, sans-serif;
  }

  h3 {
    color: #2f3146;
    font-family: 'Jost-Bold', Helvetica;
  }

  .summary::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  .summary::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  .summary::-webkit-scrollbar-thumb {
    background: #00ab75;
  }

  /* Handle on hover */
  .summary::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
